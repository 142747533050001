"use strict";

class ProductView {
    static imageGallerySelector = '#productImageGallery';
    static largeImageMagnifySelector = '#productImageMagnify';
    static engravingExampleText = 'Example Text';
    static engravingModalSelector = '#engravingModal';
    static engravingFormSelector = '#engravingForm';
    static engravingTextInputSelector = '#engravingTextInput';
    static engravingDraggableParentSelector = '#engravingDraggableWrap';
    static engravingDraggableTextSelector = '#engravingDraggableText';
    static engravingDraggableTextValSelector = '#engravingDraggableTextVal';
    static productEngravingInfoSelector = '#productEngravingInfo';
    static productEngravingInfoTextSelector = '#productEngravingInfoText';
    static productRemoveEngravingBtnSelector = '#removeProductEngravingBtn';
    static buyButtonsGroupSelector = '#buyButtonsGroup';
    static buyNowBtnSelector = '#buyNowActionBtn';
    static requestPriceModalSelector = '#requestPriceModal';
    static requestPriceFormSelector = '#requestPriceForm';
    static addToCartBtnSelector = '#addToCartActionBtn';
    static requestPriceBtnSelector = '#requestPriceBtn';
    static addedToCartModalSelector = '#cartProductAddedModal';
    static pickIntegrateProductBtnSelector = '.select-integration-product-btn';
    static pickIntegrateProductModalSelector = '#pickIntegrationProductModal';

    static onProductAddedToCart = undefined;
    static onBuyNow = undefined;

    static productId = undefined;
    static isJewelry = false;
    static imageGalleryInfo = [];
    static integrationProductsLoadTime = 0;
    static selectedIntegrationProduct = undefined;
    static currentEngraving = {
        text: null, 
        textCoords: '0.33,0.63',
        textPosX: 0,
        textPosY: 0,
    };

    static Initialize(productId, isJewelry = false) {
        ProductView.productId = productId;
        ProductView.isJewelry = isJewelry;

        // Hook image thumb click event
        $(ProductView.imageGallerySelector).on('click', '.image-thumb', function() {
            if ($(this).hasClass('active')) {
                return false;
            }
            const imageBtn = this;
            const imageId = $(this).attr('data-image-id');
            const target = $(ProductView.largeImageMagnifySelector).find('[data-image-id="'+imageId+'"]');
            const imageMediumSrc = target.find('img').attr('src');
            let image = document.createElement('img');
            // assign and onload event handler
            image.addEventListener('load', (event) => {
                $(ProductView.largeImageMagnifySelector)
                    .find('.active')
                    .addClass('d-none')
                    .removeClass('active');
                target.removeClass('d-none').addClass('active');
                $(imageBtn).removeClass('loading');
                $(ProductView.imageGallerySelector).find('.active').removeClass('active');
                $(imageBtn).addClass('active');
                $(image).remove();
            });
            // add logo to the document
            document.body.appendChild(image);
            image.class = 'd-none';
            image.src = imageMediumSrc;
            $(this).addClass('loading');
            return false;
        });

        $(ProductView.largeImageMagnifySelector).on('click', '.control-prev, .control-next', function() {
            const dir = $(this).attr('data-action');
            const current = $(ProductView.imageGallerySelector).find('.active');
            let target = null;
            if (dir === 'next') {
                if (current.is(':last-child')) {
                    target = $(ProductView.imageGallerySelector).children().first();
                } else {
                    target = current.next();
                }
            } else {
                if (current.is(':first-child')) {
                    target = $(ProductView.imageGallerySelector).children().last();
                } else {
                    target = current.prev();
                }
            }
            if (target) {
                target.trigger('click');
            }
            return false;
        });

        $(ProductView.engravingTextInputSelector).on('keyup', function(event) {
            const text = $(event.target).val();
            if (text.length > 0) {
                $(ProductView.engravingDraggableTextValSelector).html(text);
                ProductView.currentEngraving.text = text;
            } else {
                $(ProductView.engravingDraggableTextValSelector).html(ProductView.engravingExampleText);
                ProductView.currentEngraving.text = null;
            }
        });

        $(ProductView.engravingFormSelector).on('submit', function(event) {
            $(ProductView.productEngravingInfoTextSelector).html(ProductView.currentEngraving.text);
            if (ProductView.currentEngraving.text.length > 0) {
                $(ProductView.productEngravingInfoSelector).addClass('filled-in');
            } else {
                $(ProductView.productEngravingInfoSelector).removeClass('filled-in');
            }
            $(ProductView.engravingModalSelector).modal('hide');
            ProductView.updatePrice();
            event.preventDefault();
            return false;
        });

        $(ProductView.productRemoveEngravingBtnSelector).on('click', function() {
            ProductView.currentEngraving.text = null;
            $(ProductView.engravingTextInputSelector).val('');
            $(ProductView.productEngravingInfoSelector).removeClass('filled-in');
            return false;
        });

        App.cachedScript(BaseURL + '/assets/frontend/js/libs/interact.min.js').then(async function() {
            // Hook draggables
            interact(ProductView.engravingDraggableTextSelector).draggable({
                inertia: false,
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: 'parent',
                        endOnly: true
                    })
                ],
                autoScroll: true,
                listeners: {
                    move (event) {
                        const target = event.target;
                        const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                        const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
                        const xPct = x / target.parentElement.clientWidth;
                        const yPct = y / target.parentElement.clientHeight;
                        target.setAttribute('data-x', x);
                        target.setAttribute('data-y', y);
                        target.style.top = (yPct * 100) + '%';
                        target.style.left = (xPct * 100) + '%';
                    },
                    end (event) {
                        const target = event.target;
                        const x = (parseFloat(target.getAttribute('data-x')) || 0);
                        const y = (parseFloat(target.getAttribute('data-y')) || 0);
                        const xPct = x / target.parentElement.clientWidth;
                        const yPct = y / target.parentElement.clientHeight;
                        
                        ProductView.currentEngraving.textCoords = xPct + ',' + yPct;
                        ProductView.currentEngraving.textPosX = x;
                        ProductView.currentEngraving.textPosY = y;
                    }
                }
            });
        });
        
        $(ProductView.engravingModalSelector).on('shown.bs.modal', function (event) {
            ProductView.applyEngravingState(ProductView.currentEngraving);
        });

        // Apply initial engraving state
        ProductView.applyEngravingState(ProductView.currentEngraving);

        // Hook buy now btn
        $(ProductView.buyNowBtnSelector).on('click', function() {
            const btn = $(this);
            if (!ProductView.canProceedToBuy()) {
                return false;
            }
            UI.buttonStartLoading(btn);
            App.post(BaseURL + '/shop/checkout/buy-now', {
                product: productId,
                integrationProduct: ProductView.selectedIntegrationProduct,
                engravingText: ProductView.currentEngraving.text,
                engravingTextCoords: ProductView.currentEngraving.textCoords,
            }, function(resp) {
                UI.buttonStopLoading(btn);
                // On buy now event
                if (typeof ProductView.onBuyNow == 'function') {
                    ProductView.onBuyNow();
                }
                UI.handleJsonResponse(resp);
            });
            return false;
        });

        // Hook add to cart btn
        $(ProductView.addToCartBtnSelector).on('click', function() {
            const btn = $(this);
            if (!ProductView.canProceedToBuy()) {
                return false;
            }
            UI.buttonStartLoading(btn);
            App.post(BaseURL + '/shop/cart/add', {
                product: productId,
                integrationProduct: ProductView.selectedIntegrationProduct,
                engravingText: ProductView.currentEngraving.text,
                engravingTextCoords: ProductView.currentEngraving.textCoords,
            }, function(resp) {
                UI.buttonStopLoading(btn);
                if (resp.response) {
                    $('.cart-counter').removeClass('hide');
                    $('.cart-counter').html(resp.cartItemsCount);
                    let modal = $(ProductView.addedToCartModalSelector);
                    modal.find('.modal-product-title').html(resp.itemTitle);
                    if (resp.itemShortInfo && resp.itemShortInfo.length > 0) {
                        modal.find('.modal-product-description').html(resp.itemShortInfo);
                    } else {
                        modal.find('.modal-product-description').html('').hide();
                    }
                    modal.find('.modal-product-price').html(resp.itemPriceText);
                    modal.modal('show');
                    UI.handleGtmEvents(resp);
                    // On add to cart event
                    if (typeof ProductView.onProductAddedToCart == 'function') {
                        ProductView.onProductAddedToCart();
                    }
                } else {
                    UI.handleJsonResponse(resp);
                }
            });
            return false;
        });

        if (!ProductView.canProceedToBuy()) {
            $(ProductView.buyButtonsGroupSelector).hide();
        }

        // Hook the pick integration product button
        $(ProductView.pickIntegrateProductBtnSelector).on('click', function() {
            if (!ProductView.shouldLoadIntegrationProducts()) {
                $(ProductView.pickIntegrateProductModalSelector).modal('show');
            } else {
                UI.startLoadingOverlay();
                ProductView.loadIntegrationProducts((resp) => {
                    UI.stopLoadingOverlay();
                    if (resp.response) {
                        $('#pickIntegrationProductsContent').html(resp.productsHtml);
                        $(ProductView.pickIntegrateProductModalSelector).modal('show');
                    } else {
                        UI.handleJsonResponse(resp);
                    }
                });
            }
            return false;
        });

        // Preload integration products
        if (ProductView.isJewelry) {
            ProductView.loadIntegrationProducts((resp) => {
                if (resp.response && resp.singular) {
                    const id = $('#pickIntegrationProductsContent').find('[data-product-select]').attr('data-product-select');
                    ProductView.onSelectedIntegrationProduct(id);
                }
            });
        }

        $('#pickIntegrationProductsContent').on('click', '[data-product-select]', function() {
            const id = $(this).attr('data-product-select');
            ProductView.onSelectedIntegrationProduct(id);
            return false;
        });

        // Hook request price btn
        $(ProductView.requestPriceBtnSelector).on('click', function() {
            $(ProductView.requestPriceModalSelector).modal('show');
            return false;
        });

        // Request price form
        $(ProductView.requestPriceFormSelector).on('submit', function(event) {
            const btn = $(this).find('[type="submit"]');
            UI.buttonStartLoading(btn);
            App.post(BaseURL + '/shop/product/request-price', ProductView.requestPriceFormSelector, function(resp) {
                UI.buttonStopLoading(btn);
                if (resp.response) {
                    $(ProductView.requestPriceModalSelector).modal('hide');
                }
                UI.handleJsonResponse(resp);
            });
            event.preventDefault();
            return false;
        });
    }

    static canProceedToBuy() {
        return (!ProductView.isJewelry || ProductView.selectedIntegrationProduct !== undefined);
    }

    static shouldLoadIntegrationProducts() {
        return (ProductView.integrationProductsLoadTime == 0 || (Date.now() - ProductView.integrationProductsLoadTime) > 60000);
    }

    static loadIntegrationProducts(cb = null) {
        App.post(BaseURL + '/shop/product/integration-products', {
            product: ProductView.productId,
        }, function(resp) {
            if (resp.response) {
                ProductView.integrationProductsLoadTime = Date.now();
                $('#pickIntegrationProductsContent').html(resp.productsHtml);
            }
            if (cb) {
                cb(resp);
            }
        });
    }

    static onSelectedIntegrationProduct(productId) {
        ProductView.selectedIntegrationProduct = productId;
        $(ProductView.buyButtonsGroupSelector).show();
        $('#jewelryProductIntegrationBtnContainer').hide();
        $(ProductView.pickIntegrateProductModalSelector).modal('hide');
        $('#jewelryProductIntegrationPreviewContent').html('<div class="loading"><span class="spinner-border" role="status" aria-hidden="true"></span><span>Loading...</span></div>');
        $('#jewelryProductIntegrationPreview').fadeIn('fast');
        App.post(BaseURL + '/shop/product/preview',{
            product: productId
        }, function(resp) {
            if (resp.response) {
                $('#jewelryProductIntegrationPreviewContent').html(resp.resultHtml);
            } else {
                UI.handleJsonResponse(resp);
            }
        });
        ProductView.updatePrice();
    }

    static applyEngravingState(engraving) {
        if (engraving != undefined) {
            let coords = {
                x: engraving.textCoords.split(',')[0],
                y: engraving.textCoords.split(',')[1]
            };
            const x = coords.x * $(ProductView.engravingDraggableParentSelector).width();
            const y = coords.y * $(ProductView.engravingDraggableParentSelector).height();
            $(ProductView.engravingDraggableTextValSelector).html(engraving.text || ProductView.engravingExampleText);
            $(ProductView.engravingDraggableTextSelector).attr('data-x', x);
            $(ProductView.engravingDraggableTextSelector).attr('data-y', y);
            $(ProductView.engravingDraggableTextSelector).css('top', (coords.y * 100) + '%');
            $(ProductView.engravingDraggableTextSelector).css('left', (coords.x * 100) + '%');
        }
    }

    static updatePrice() {
        App.post(BaseURL + '/shop/product/price', {
            product: ProductView.productId,
            integrationProduct: ProductView.selectedIntegrationProduct,
            engravingText: ProductView.currentEngraving.text,
        }, function(resp) {
            if (resp.response) {
                $('#fullPrice').html(resp.priceFormatted);
            }
        });
    }
}